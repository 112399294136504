import React, { useState, useEffect } from 'react'
import Plot from 'react-plotly.js'
import { csv } from 'd3'
import { BounceLoader } from 'react-spinners'
import { Card } from 'react-bootstrap'
import './miniOracle.css'

interface miniOracleProps {
  data: any
}

export const MiniOracle = (props: miniOracleProps): JSX.Element => {
  const [xValues, setXValues] = useState<string[]>([])
  const [yValues, setYValues] = useState<string[]>([])
  const [zValues, setZValues] = useState<string[]>([])
  const [thicknessValues, setThicknessValues] = useState<string[]>([])
  const [colorValues, setColorValues] = useState<string[]>([])

  const data: Plotly.Data[] = [
    {
      x: xValues,
      y: yValues,
      z: zValues,
      mode: 'markers',
      // colorscale: "YlGnBu",
      marker: {
        size: 3,
        color: colorValues,
      },
      type: 'scatter3d',
      hovertemplate:
        '<br>Thickness=%{text:.3f}"' +
        "<br>X=%{x:.3f}'<br>Y=%{y:.3f}'<br>Z=%{z:.3f}'<extra></extra>",
      text: thicknessValues,
    },
  ]

  useEffect(() => {
    if (props.data !== '') {
      // csv(props.data).then((data: any) => {
      let newData = processData(props.data.data)
      setXValues(newData.x)
      setYValues(newData.y)
      setZValues(newData.z)
      setThicknessValues(newData.thickness)
      setColorValues(newData.colors)
      // })
    }
  }, [props])

  if (props.data == '') {
    return <div>Loading...</div>
  } else {
    return (
      <div className='MiniOracle'>
        {xValues.length === 0 ? (
          <div className='MiniOracle__loader-div'>
            <BounceLoader color='white'></BounceLoader>
          </div>
        ) : (
          <Card className='MiniOracle__card'>
            <Card.Body className='MiniOracle__card-body'>
              <div className='MiniOracle__gradient-holder-div'>
                <div className='MiniOracle__color-gradient-div'></div>
                <div className='MiniOracle__gradient-text-holder'>
                  <p>0.10"</p>
                  <p>2.00"</p>
                </div>
              </div>
              <div className='MiniOracle__plot-holder-div'>
                <Plot
                  data={data}
                  // onClick={this.pointClick}
                  useResizeHandler
                  style={{ width: '100%', height: '100%' }}
                  layout={{
                    modebar: {
                      bgcolor: 'rgba(0,0,0,0)',
                    },
                    showlegend: false,
                    uirevision: 'true',
                    autosize: true,
                    paper_bgcolor: 'white',
                    margin: {
                      l: 0,
                      r: 0,
                      b: 0,
                      t: 0,

                      // pad: 4,
                    },
                  }}
                  config={{
                    responsive: true,
                    // displayModeBar: false,
                    modeBarButtonsToRemove: ['resetCameraLastSave3d'],
                    displaylogo: false,
                  }}></Plot>
              </div>
            </Card.Body>
          </Card>
        )}
      </div>
    )
  }
}

const processData = (allRows: any) => {
  let x = []
  let y = []
  let z = []
  let thickness = []
  let colors = []
  for (let i = 0; i < allRows.length; i++) {
    let row = allRows[i]
    x.push(row['x'])
    y.push(row['y'])
    z.push(row['z'])
    thickness.push(row['thickness'])
    colors.push(row['colors'])
  }
  return { x: x, y: y, z: z, thickness: thickness, colors: colors }
}
